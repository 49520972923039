Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.descIsRequired = "Please enter brief description";
exports.shouldBeMinDesc = "The text should be much than 4 symbols";
exports.imageRequired = "Please select image";
exports.aiTagText = "Powered by Gen AI";
exports.aiLoadertext = "Sculpting your thoughts... Coming right up!";
exports.aiLoaderSubtext = "Powered by AI";
exports.aiProcessErrorMessage = "Failed to process! Please try again.";
